import logoImageInverted from 'assetsPath/img/logo_inverted.png';

import { createAdminPage } from '../../layouts/shared/adminPage';
import HyUIInputText from '../../components/forms/input-text.vue';

createAdminPage({
    components: {
        [HyUIInputText.name]: HyUIInputText,
    },
    data() {
        return {
            logoImageInverted,
        };
    },
    mounted() {},
});
