<template lang="pug">
include ../../layouts/templates/_includes

div(class=ns + 'input')
    label.u-spacing-bottom-s(
        v-if="label?.length",
        :for="id",
        :class="{'u-acc-hide': this.hideLabel, 'u-inline-block': tooltipText !== ''}"
    ) {{label}}
    form-tooltip(
        v-if="tooltipText?.length",
        :tooltipText="tooltipText"
    )
    input(
        v-if="!textarea",
        v-model='inputValue'
        :value="inputValue",
        :type="inputtype",
        :autocomplete="inputtype === 'password' ? 'off' : 'auto'",
        :id="id",
        :name="name",
        :placeholder="placeholder",
        :disabled="disabled",
        :readonly="readonly",
        :class="inputClass",
        :required="required",
        :maxlength="maxlength",
        :data-test-id="dataTestId.length ? dataTestId + '-value-' + name : null",
        @change="onChange",
        @input="onInput",
        @keydown.enter="onEnter",
        @focus="onFocus",
        @blur="onBlur"
    )
    textarea(
        v-if="textarea",
        :id="id",
        :value="inputValue",
        :name="name",
        :maxlength="maxlength",
        :placeholder="placeholder",
        :disabled="disabled",
        :readonly="readonly",
        :class="inputClass",
        :style="{width: textareaWidth}"
        :data-test-id="dataTestId.length ? dataTestId + '-value-' + name : null",
        @change="onChange",
        @input="onInput",
        @keydown.enter="onEnter"
    )
    slot
</template>

<script>
import FormTooltipComponent from '../forms/formTooltip.component.vue';

export default {
    name: 'hyui-input',
    components: {
        [FormTooltipComponent.name]: FormTooltipComponent,
    },
    props: {
        name: {
            default: 'name',
            type: String,
        },
        value: {
            default: '',
            type: [String, Number, Boolean],
        },
        id: String,
        placeholder: {
            default: '',
            type: String,
        },
        disabled: {
            default: false,
            type: Boolean,
        },
        readonly: {
            default: false,
            type: Boolean,
        },
        label: String,
        hiddenLabel: {
            default: false,
            type: Boolean,
        },
        textareaWidth: {
            default: 'auto',
            type: String,
        },
        inputtype: {
            default: 'text',
            type: String,
        },
        inputClass: String,
        textarea: {
            default: false,
            type: Boolean,
        },
        textDirection: String,
        required: {
            default: false,
            type: Boolean,
        },
        tooltipText: {
            default: '',
            type: String,
        },
        maxlength: {
            default: 524288,
            type: Number,
        },
        dataTestId: {
            default: '',
            type: String,
        },
    },
    data() {
        return {
            hideLabel: this.hiddenLabel || false,
            inputValue: this.value !== undefined ? this.value : '',
        };
    },
    watch: {
        value(val) {
            this.inputValue = val;
        },
    },
    methods: {
        onChange(evt) {
            this.$emit('onChange', {
                name: this.name,
                value: evt.target.value,
            });
        },
        onInput(evt) {
            this.$emit('onInput', evt);
        },
        onEnter(evt) {
            this.$emit('onEnter', {
                name: this.name,
                value: evt.target.value,
            });
        },
        onFocus(evt) {
            this.$emit('onFocus', evt);
        },
        onBlur(evt) {
            this.$emit('onBlur', evt);
        },
    },
};
</script>
