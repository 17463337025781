// @ts-check
import { createDefaultPage } from './defaultPage';
import { UI_TRANSLATIONS } from '../../commons/store/actionNames';

export function createAdminPage(mixin = {}) {
    const page = createDefaultPage({
        mixins: [mixin],
        mounted() {
            if (this.$store) {
                this.$store.get(UI_TRANSLATIONS, (translations) => {
                    if (this.pageNameCode) {
                        this.pageTitle = translations[this.pageNameCode] || '';
                    }
                });
            }
        },
        methods: {
            clearInvestmentLevels(evt) {
                window.location.href = evt.target.parentElement.href || evt.target.href;
            },
        },
    });

    page.mount(mixin.el || '#app');

    return page;
}
