<template lang="pug">
include ../../layouts/templates/_includes.pug
div(
    @mouseover="hover=true",
    @mouseleave="hover=false",
    class="cm-form-tooltip",
    :class="{'in-table': insideTable}"
).u-inline-block
    div(
        class="cm-form-tooltip__icon"
        :title="nativeTooltip ? tooltipText : ''"
    )
        span(v-if="!invertedColors")
            +orb-style('xs', 'g-brand1-bg')
                +orb-icon('questionmark', 'white')
        span(v-else)
            +orb-style('xs', 'white')
                +orb-icon('questionmark', 's', 'brand1')
    div(
        v-if="hover && !nativeTooltip",
        class="cm-form-tooltip__container",
        :class="{'in-table': insideTable, 'rotate': rotateTooltip, 'to-bottom': toBottomTooltip, 'reset-width': resetWidth}"
    )
        p(class="cm-form-tooltip__content") {{tooltipText}}
</template>

<script>
export default {
    name: 'form-tooltip',
    props: {
        tooltipText: String,
        insideTable: Boolean,
        invertedColors: Boolean,
        rotateTooltip: Boolean,
        toBottomTooltip: Boolean,
        resetWidth: {
            type: Boolean,
            default: false,
        },
        nativeTooltip: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            hover: false,
        };
    },
};
</script>

<style scoped></style>
